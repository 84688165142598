body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.switch-wrapper {
  position: relative;
}
 
.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.ck-header {
  background-color: #101010;
}

.ck-footer {
  background-color: #101010;
  padding-top: 30px;
  padding-bottom: 30px;
  color: white;
}

.ck-footer .ui.vertical.text.menu .header.item {
  color: white;
}

.ck-footer .ui.vertical.menu.ck-footer-info {
  width: 100%;
  color: #ccc;
}

.ck-footer a {
  color: white;
}

.ck-footer a:hover {
  color: #ccc;
}

.ck-title-section {
  margin-top: 2em;
  margin-bottom: 4em;
}

.ck-title-section h1.ui.header {
  font-size: 3em;
}

.ck-title-section p.lead {
  font-size: 1.5em;
}

.ck-title-section.inverted h1.ui.header {
  color: white;
}

.ck-title-section.inverted p.lead {
  color: #F1F1F1;
}

.ck-title-section.inverted a.backlink {
  color: #ccc;
}

.backlink {
  cursor: pointer;
}

p.lead {
  font-size: 130%;
}

.ck-segment-dark p {
  color: #ccc; 
}

p.lead.emphasis {
  color: #C4004B;
}

.ck-hero h1.ui.header {
  font-size: 2em;
  margin-top: 1.5em;
  font-weight: normal;
}

.ck-hero h2.ui.header {
  font-size: 1.5em;
  margin-top: 1em;
  font-weight: normal;
}

.ui.segment.ck-hero {
  padding-bottom: 3em;
}

i.sx-red.icon {
  color: #C4004B;
}

.ui.table .ui.progress,
.ui.table .ui.progress:first-child,
.ui.table .ui.progress:last-child {
  margin-top: 0;
  margin-bottom: 0;
}


span.rating-text {
  display: block;
  padding-bottom: 3px;
}

span.rating-text.rating-text-0 {
  color: #999;
}

span.rating-text.rating-text-1 {
  color: #BC5736;
}

span.rating-text.rating-text-2 {
  color: #d18851;
}

span.rating-text.rating-text-3 {
  color: #295178;
}

span.rating-text.rating-text-4 {
  color: #6BAE3C;
}

span.rating-text.rating-text-5 {
  color: #2E5A27;
}





.cx-rating-input-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #CCC;
  margin-right: 2px;
  transform: skew(-20deg);
  transition: background-color 0.6s, opacity 0.6s;
  cursor: pointer;
}

.cx-rating-input-icon:first-child {
  border-top-left-radius: 6px;
}

.cx-rating-input-icon:last-child {
  border-bottom-right-radius: 6px;
}

.cx-rating-input-icon.active {
  background-color: #C4004B;
}

.cx-rating-input-icon.cx-rating-input-icon-1.active {
  background-color: #E51600;
}

.cx-rating-input-icon.cx-rating-input-icon-2.active {
  background-color: #EB4801;
}

.cx-rating-input-icon.cx-rating-input-icon-3.active {
  background-color: #F27602;
}

.cx-rating-input-icon.cx-rating-input-icon-4.active {
  background-color: #F8AD03;
}

.cx-rating-input-icon.cx-rating-input-icon-5.active {
  background-color: #FFE005;
}

.cx-rating-input-icon.selected {
  opacity: 0.7;
}

.sx-share-widget {

}

.sx-share-widget input.sx-share-url {
  border-color: transparent;
  width: 95%;
}

.sx-share-widget .sx-copy-icon {
  cursor: pointer;
  float: right;
}

.sx-date .sx-date-ago {
  display: inline-block;
  margin-left: 4px;
}

.grecaptcha-badge { visibility: hidden; }

@media only screen and (min-width: 768px) {
  .ck-hero h1.ui.header {
    font-size: 4em;
  }

  .ck-hero h2.ui.header {
    font-size: 1.7em;
    margin-top: 1.5em;
  }

  .ui.segment.ck-hero {
    padding-bottom: 6em;
  }
}

@media only screen and (max-width: 767px) {
  .ck-footer .column {
    text-align: center !important;
  }
}